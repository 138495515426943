import * as React from 'react'
import Layout from '../components/Layout/Layout'
import { isValidURL } from '../utilities'
import clsx from 'clsx'
import Footer from '../components/Layout/Footer'
import petalLeafImg from '../images/petalleaf_capture.png'
import realDelicacyImg from '../images/realdelicacy_capture.png'

export default function Business() {
  return (
    <Layout>
      <div className='max-w-xl mt-12 mb-10 mx-12 md:mx-40 xl:mx-auto md:mt-24'>
        <p className=' text-lg font-light'>
          Running business adds new dimensions to interact with and understand
          the society. I learn from it more than what books can tell. And I am
          always amazed by the creativity and wisdom of every entrepreneur.
        </p>
      </div>
      <div className='mt-10 max-w-5xl md:mx-8 xl:mx-auto flex flex-col md:flex-row'>
        <Card
          imageSrc={petalLeafImg}
          title={'Petal Leaf'}
          description={'A real flower specialty bakery'}
          className='mx-6 mb-6 md:mb-0 md:mr-8 xl:mr-16 md:ml-0'
          url={'https://thepetalleaf.com/'}
        />
        <Card
          imageSrc={realDelicacyImg}
          title={'Real Delicacy'}
          description={'A wild mushrooms wholesaler'}
          className='mx-6 md:mx-0'
          url={'https://realdelicacy.com/catalog'}
        />
      </div>
      <Footer />
    </Layout>
  )
}

function Card({ imageSrc, title, description, className, url }) {
  const handleClick = () => {
    if (isValidURL(url)) window.open(url)
  }
  return (
    <button
      onClick={handleClick}
      className={clsx(
        'cursor-pointer rounded-xl shadow-lg hover:shadow-xl hover:shadow-slate-300 transition-shadow tracking-wide overflow-hidden flex-1',
        className
      )}
    >
      <div className='relative pb-[50%]'>
        <img
          src={imageSrc}
          alt={title}
          className='absolute w-full h-full object-cover '
        />
      </div>
      <div className='p-8 text-center'>
        <h1 className='text-lg font-semibold mb-4'>{title}</h1>
        <p>{description}</p>
      </div>
    </button>
  )
}
